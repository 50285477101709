import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {RoleEnum} from '@core/security/enum/role.enum';

export enum MatSpinnerColorEnum {
    Primary = 'primary',
    PrimaryVo = 'primary-vo',
    PrimaryBg = 'primary-bg',
    Dark = 'dark',
    DarkBg = 'dark-bg',
    Orange = 'orange',
    OrangeBg = 'orange-bg',
    Red = 'red',
    Blue = 'blue',
    Purple = 'purple',
    PurpleBg = 'purple-bg',
    Green = 'green',
    NoValue = 'no-value',
    NoValueDark = 'no-value-dark-mode'
}

@Component({
    selector: 'app-progress-circle[percentage]',
    templateUrl: './progress-circle.component.html',
    styleUrls: ['./progress-circle.component.scss'],
})
export class ProgressCircleComponent implements OnChanges {
    readonly isRoleOrganizationHo: boolean;

    @Input()
    percentage?: number;

    @Input()
    readonly diameter: number = 5;

    @Input()
    readonly fontSize: number = 1.3;

    @Input()
    readonly isDarkMode: boolean = false;

    @Input()
    readonly pointer: boolean = false;

    @Input()
    value?: string;

    @Input()
    readonly strokeWidth?: number = 0.3;

    @Input()
    color!: MatSpinnerColorEnum;

    constructor(
        private authorizationService: AuthorizationService,
    ) {

        this.isRoleOrganizationHo = this.authorizationService.isGranted(RoleEnum.RoleOrganizationHo);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.percentage) {
            this.#handleColorClass();
        }
    }

    #handleColorClass(): void {
        const percentage: number | undefined = this.percentage;
        const defaultColor: MatSpinnerColorEnum = this.isDarkMode ? MatSpinnerColorEnum.NoValueDark : MatSpinnerColorEnum.NoValue;

        if (undefined === percentage || percentage === 0) {
            this.color = defaultColor;

            return;
        }

        this.color = percentage <= 50 ? MatSpinnerColorEnum.Red : this.percentage === null ? defaultColor : this.color;
    }
}
